export const QuestionsListItems = [
  {
    label: 'Adapt the job description to make it more inclusive (ethnicity, gender, etc.)',
    value: {}
  },
  {
    label: 'Among the following options, which best matches the job (returns a JSON list): Full-time, Permanent, Temporary, Part-time, Fixed-term contract, Internship, Apprenticeship, Freelance, Civic service',
    value: {}
  },
  {
    label: "Does this offer include a managerial status?",
    value: {}
  },
  {
    label: 'Deduce the main values of the company?',
    value: {}
  },
  {
    label: 'Exact address of the job',
    value: {}
  },
  {
    label: "Is language training offered to employees?",
    value: {}
  },
  {
    label: 'Is speaking French mandatory?',
    value: {}
  },
  {
    label: 'Is the position a permanent contract?',
    value: {}
  },
  {
    label: "Is there a start date for this position?",
    value: {}
  },
  {
    label: 'Summarize the job for me',
    value: {}
  },
  {
    label: 'What are the career progression opportunities in this position or within the company?',
    value: {}
  },
  {
    label: 'What is the average salary for this job in euros per month?',
    value: {}
  },
  {
    label: 'What is the job title?',
    value: {}
  },
  {
    label: "What is the required level of education for this job? (default: Bachelor's degree)",
    value: {}
  },
  {
    label: "What is the start date for this job? (DD-MM-YYYY)",
    value: {}
  },
  {
    label: 'What is the typical work space in the company (fast, moderate, flexible)?',
    value: {}
  }
]