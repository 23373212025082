import React from "react"
import styled from "styled-components"
import Form from "./form/index"
import Result from "./result/index"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`


const Asking = (props) => {
  return (
    <StyledContainer
      color="#22B5F3"
    >
      <Form
        response={props.response}
        postJobAsking={props.postJobAsking}
        params={props.queryParams}
        jobAsking={props.jobAsking}
      />
      {props.queryParams?.key ?
        (
          <>
            <Result 
              getJobAsking={props.getJobAsking}
              jobAsking={props.jobAsking}
              jobAskingkey={props.queryParams.key}
              email={props.queryParams.email}
            />
          </>
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Asking;