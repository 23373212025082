import React from 'react';
import Select, { components } from 'react-select';
import XMark from "components/icons/xMark";
import { motion } from "framer-motion";
import styled from "styled-components"
import Input from "components/common/input"
import { QuestionsListItems }  from './questionsList';


const SelectedQuestions = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SelectedLocation = styled.div`
  display: flex;
  align-items: center;
  background-color: #eaffff; 
  color: #1dafb6;
  border: 1px solid #1dafb6;
  border-radius: 4px; 
  padding: 8px 12px; 
  gap: 8px;
  font-size: 14px;

`;

const RemoveButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default function FormStep({ 
  title,
  setTitle,
  description,
  setDescription,
  questions,
  setQuestions,
  dControls
}) {

  const handleSelectQuestion = (selectedOptions) => {
    setQuestions(selectedOptions || []);
  }

  const handleRemoveQuestion = (questionToRemove) => {
    const newQuestions = questions.filter(item => item.value !== questionToRemove.value);
    setQuestions(newQuestions);
  }

  const CustomValueContainer = ({ children, ...props }) => {
    const hasInputValue = !!props.selectProps.inputValue;

    return (
      <components.ValueContainer {...props}>
        {!hasInputValue && (
          <components.Placeholder {...props}>
            {props.selectProps.placeholder}
          </components.Placeholder>
        )}
        {React.Children.map(children, child =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </components.ValueContainer>
    );
  };

  const customComponents = {
    MultiValue: () => null, 
    ValueContainer: CustomValueContainer
  };

  return (
    <motion.div
      className="control"
      initial={{ opacity: 0, x: 80 }}
      animate={dControls}
    >
      <div className="control">
        <div className="label">Enter a job title</div>
        <Input 
          changed={event => setTitle(event.target.value)}
          type="input"
          placeholder="Enter job title"
          value={title}
          content="text"
        />
      </div>
      <div className="control">
        <div className="label">Enter a job description</div>
        <Input 
          changed={event => setDescription(event.target.value)}
          type="textarea"
          placeholder="Enter text here"
          value={description}
        />
      </div>
      <div className="control">
        <label className="label">Questions</label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          onChange={handleSelectQuestion}
          placeholder="Select question" 
          value={questions}
          isClearable={false}
          isSelectable={false}
          isRtl={false}
          options={QuestionsListItems}
          isMulti
          components={customComponents}
        />
        <SelectedQuestions>
          {questions.map((questionItem, index) => (
            <SelectedLocation key={index}>
              <RemoveButton onClick={() => handleRemoveQuestion(questionItem)}>
                <XMark />
              </RemoveButton>
              {questionItem.label}
            </SelectedLocation>
          ))}
        </SelectedQuestions>
      </div>
    </motion.div>
  )
}
