import React, {useEffect} from "react"
import styled from "styled-components"
import Spinner from 'components/common/spinner'
import ErrorMessage from "components/common/errorMessage"
import Answers from "./answers"

const StyledContainer = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  .empty {
    height: 17rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Result = (props) => {
  const key = props.jobAskingkey && props.jobAskingkey;
  const email = props.email && props.email.replace(/\s+/g, '+').toLowerCase();
  const questions = props.jobAsking?.payload?.questions;
  const answers = props.jobAsking?.payload?.response?.data;

  useEffect(() => {
    props.getJobAsking({
      key: key,
      email: email
    })
    // scrollTo('#result')
  },[])

  if (props.jobAsking.r) {
    return (
      <StyledContainer color="#008ED2">
        <Spinner />
      </StyledContainer>
    )
  } else if (props.jobAsking.s && props.jobAsking.payload) {
    return (
      <StyledContainer color="#008ED2">
        <Answers 
          questions={questions}
          answers={answers}
         />
      </StyledContainer>
    )
  } else {
    return (
      <StyledContainer color="#008ED2">
        <div className="empty">
          <ErrorMessage
            title="Something went wrong"
            subtitle="Please retry again later."
            type="error"
            min="true"
          />
        </div>
      </StyledContainer>
    )
  }
}

export default Result;