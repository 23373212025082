import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import Input from "components/common/input"
import ArrowRight from "components/icons/arrowRight"
import Sync from "components/icons/sync"
import Envolope from "components/icons/envolope"
import ErrorMessage from "components/common/errorMessage"
import StyledBtn from "styles/button"
import Cgu from "components/common/cgu"
import FormStep from "./formStep"


const StyledForm = styled.form`
  background: white;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  min-height: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .control {
      margin-bottom: 1rem;
      position: relative;

      .trash-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
    }
    .alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      text-align: center;
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        a:first-of-type {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        a {
          width: 100%;
        }
        margin-top: 0.7rem;
      }
      .logo {
        height: 3.56rem;
        width: 3.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E7F0FF;
        border-radius: 0.22rem;
        margin-bottom: 1rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        .buttons {
          flex-direction: row;
          a:first-of-type {
            margin-right: 1rem;
            margin-bottom: 0;
          }
          a {
            width: unset;
          }
        }
      }
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
`

const StyledButton = styled(StyledBtn).attrs({
  as: "button"
})`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #008ED2;
  border: none;
  cursor: pointer;
  min-width: 10rem;

`

const StyledButtonLink = styled(StyledBtn)`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #008ED2;
  border: none;
  cursor: pointer;
`

const StyledButtonLinkWhite = styled(StyledButtonLink)`
  color: #008ED2;
  background: white;
  border: 1px solid #008ED2;
`

const Form = props => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState(false);


  const dControls = useAnimation()
  const eControls = useAnimation()
  const rControls = useAnimation()


  useEffect(() => {
    if(props.jobAsking.s) {
      setDescription(props.jobAsking.payload.description);
      setTitle(props.jobAsking.payload.title);

      const formatedQuestions = props.jobAsking?.payload?.questions?.map((question) => ({
        label: question,
        value: {} 
      }));
      setQuestions(formatedQuestions);
    }

  }, [props.jobAsking])

  useEffect(() => {
    const StepTransitionSequence = async () => {
        await dControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await eControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await rControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        // Animate underlining to hover state
    }
    StepTransitionSequence()
  }, [eControls, dControls, rControls, step])

  useEffect(() => {
    if(!firstLoad) {
      if(props?.response?.s) {
        setStep(3)
        setError(false)
      }
      if(props?.response?.f) {
        setStep(3)
        setError(true)
      }
    }
    return () => {
      setFirstLoad(false)
    };
  }, [props.response])
  
  useEffect(() => {
    setStep(1)
  }, [])

  return (
    <StyledForm
      color="#008ED2"
      onSubmit={e => {
          e.preventDefault();
          if(step === 1) {
            setStep(2)
          } else {
            if(description) {
              const encodedQuestions = questions.map(question => question.label);
              props.postJobAsking({
                email: email, 
                title: title,
                description: description, 
                questions: encodedQuestions,
                marketing_data: props.params, 
                referrer: document.URL
              })
            }
          }
        }
      }
    >
      <div className="form__body">
        { step === 1 && (
          <FormStep
            title={title}
            description={description}
            questions={questions}
            setTitle={setTitle}
            setDescription={setDescription}
            setQuestions={setQuestions} 
            dControls={dControls}
          />)
        }
        {step === 2 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={eControls}
          >
            <div className="label">Enter your work email</div>
            <div className="subtitle">
              In order to receive your result, please enter your work email.
            </div>
            <Input 
              changed={event => setEmail(event.target.value)}
              type="input"
              placeholder="Enter your email"
            />
            <Cgu />
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            className="alert"
            initial={{ opacity: 0, x: 80 }}
            animate={rControls}
          >
            { error ?
              (
                <ErrorMessage
                  title="Something went wrong"
                  subtitle="Please retry again later."
                  type="error"
                  min="true"
                />
              )
              :
              (
                <>
                  <div className="logo">
                    <Envolope color="#008ED2" />
                  </div>
                  <div className="label">We sent your results on your email address</div>
                  <div className="subtitle">
                    Please click on the link in the email to access your results
                  </div>
                </>
              )
            }
            <div className="buttons">
                <StyledButtonLinkWhite
                  to="/job-analysis/asking/"
                  onClick={() => setStep(1)}
                >
                  <span className="mr-1">Try another text</span><Sync color="#008ED2"/>
                </StyledButtonLinkWhite>
                <StyledButtonLink
                    to="https://hrflow.ai/signup"
                    target="_blank"
                    rel="noreferrer"
                >
                  <span className="mr-1">Register now</span><ArrowRight color="#FFF" />
                </StyledButtonLink>                
              </div>
          </motion.div>
        )}
      </div>
      { step !== 3 && (
          <div className="form__footer">
            <StyledButton
                disabled={props?.response?.r}
                loading={props?.response?.r}
            >
                 { (props.response.r) ?
                  (
                    <div style={{display: 'flex', justifyContent: 'center', width:"100%"}}>
                      <span className="loader"></span>
                    </div>
                  )
                  :
                  (
                    <>
                      <span className="mr-1">Analyze</span><ArrowRight color="#FFF" />
                    </>
                  )
                }
            </StyledButton>
          </div>
        )
      }
    </StyledForm>
  )
}


export default Form
