import React, { useState } from "react"
import styled from "styled-components"
import Accordion from "../../../common/accordion";

const StyledContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`
const StyledSectionTitle = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const StyledAccordionsWrapper =  styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
`

const Answers = (props) => {
  
  return (
    <StyledContainer>
      <StyledSectionTitle>Result</StyledSectionTitle>
      <StyledAccordionsWrapper>
        {props.questions.map((question, index) => {
          const answer = props.answers[index];
          return (
            <Accordion key={index} title={question} isActive={index === 0}>
              <p style={{ marginTop: "0" }}>{answer}</p>
            </Accordion>
          );
        })}
      </StyledAccordionsWrapper>
    </StyledContainer>
  )
}

export default Answers;